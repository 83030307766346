.md-text-editor:hover, .md-text-editor:focus {
  box-shadow: 0 0 0 1px #eb6967, 0 0 0 #eb6967, 0 1px 1px #eb6967; }

.md-text-editor--error {
  box-shadow: 0 0 0 1px #dc283e, 0 0 0 #dc283e, 0 1px 1px #dc283e; }

.md-text-preview {
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  min-height: 32px;
  cursor: text; }
  .md-text-preview:hover {
    border-color: #eb6967; }
  .md-text-preview--disabled {
    color: #00000040;
    background-color: #f5f5f5;
    cursor: not-allowed; }
    .md-text-preview--disabled:hover {
      border-color: #d9d9d9; }
  .md-text-preview--error {
    border: 1px solid #dc283e; }
    .md-text-preview--error:hover {
      border-color: #dc283e; }

.md-text-toolbar-helpers {
  position: absolute;
  top: 4px;
  right: 12px;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 16px; }

.w-md-editor-text-input::selection {
  background-color: #df3f41;
  color: #fff; }
